import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import styled from "styled-components";
import bg from "../Logos/LoginGraphics-01-01.png";
import { breakpoints as bp } from "../GlobalStyle";

const Styles = styled.div`
  .col-sm-2 {
    color: black;
  }
  .bg_image {
    padding-left: 10px;
  }
  .link_login {
    color: blue;
    margin-top: -73px;
    padding-right: 30px;
    padding-bottom: 5px;
  }
  .link_forgot {
    color: dodgerblue;
    padding-bottom: 30px;
  }
  .form {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .label {
    padding-right: 37px;
    padding-bottom: 5px;
  }
  .label {
    padding-right: 10px;
    padding-bottom: 5px;
  }
  .button_login {
    padding-left: 200px;
    borderradius: 50;
  }
  .rcorners2 {
    border-radius: 45px;
    border: 2px solid #000000;
    padding-left: -10px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 20px;
    width: 700px;
    height: 280px;
    margin-left: 2rem;
  }
  @media (max-width: ${bp.mobile}) {
    .rcorners2 {
      border-radius: 45px;
      border: 2px solid #000000;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 60px;
      padding-bottom: 20px;
      width: 300px;
      height: 320px;
      margin-left: 0rem;
      margin-top: -1.5rem;
    }
`;

export default function ChangePass() {
  const history = useHistory();
  var data = localStorage.getItem("id");
  const [res, setRes] = useState([]);
  const [password, setPassword] = useState("");
  const [cnfm, setCnfm] = useState("")

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("token");

  function passchange() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      pass: password,
      token: id,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${process.env.React_App_ccbt}/resetpassword`,
      requestOptions
    )
      .then((response) => response.text())
      // .then((result) => console.log(result))
      .then((result) => setRes(result))
      .catch((error) => console.log("error", error));

    if (res !== "Password successfully changed") {
      alert("Password changed!!");
      history.push("/login");
    } else {
      alert("ALERT!! Check your reset password link.");
    }
  }

  return (
    <div>
      <Header />
      <Styles>
        <div
          class="bg_image"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            height: "89vh",
            color: "#000000",
          }}
        >
          <h1>
            <br />
          </h1>
          <div className="rcorners2">
            <div className="col-sm-6 offset-sm-3">
              <input
                type="password"
                placeholder="Enter New Password"
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
              />
              <br />
              <input
                type="password"
                placeholder="Re-enter New Password"
                onChange={(e) => setCnfm(e.target.value)}
                className="form-control"
              />
              <br />
              <button
                disabled={!password || password != cnfm}
                onClick={passchange}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Styles>
    </div>
  );
}
