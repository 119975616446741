import React from "react";
import Header from "../../Header";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function Qualification() {
  const Styles = styled.div`
    /* CSS */

    #grid-container {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 8px;
      padding: 0px;
    }

    #grid-container div {
      background-color: lightgray;
      text-align: left;
      padding: 0px;
      font-size: 18px;
    }

    #box-1 {
      grid-row-start: 1;
      grid-row-end: 7; /* substitute this value for your sidebar’s vertical row height */
      height: 870px;
      width: 210px;
      position: sticky;
      top: 0px;
    }
    #box-2 {
      background-color: white;
    }

    #data {
    }
  `;
  return (
    <div>
      <Header />
      <Styles>
        <div id="grid-container">
          <div id="box-1">
            <h5>Qualification</h5>
            <br />
            <br />
            <Link to="/ld">Linguistic Details</Link>
            <br />
            <br />
            <Link to="/sed">Secular Educational Details</Link>
            <br />
            <br />
            <Link to="/theoedu">Theological Educational Details</Link>
            <br />
            <br />
            <Link to="/ct">Computer Training</Link>
            <br />
            <br />
            <Link to="/sp">Software Programs</Link>
            <br />
            <br />
            <Link to="/bts">Bible Translation Software</Link>
          </div>
        </div>
      </Styles>
    </div>
  );
}
