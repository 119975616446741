import React, { Component } from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import Header from "../../Header";
import bg from "../../../Logos/CCBT_Logo-GreyHome-01.png";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import { breakpoints as bp } from "../../../GlobalStyle";

function Courses() {
  const username = JSON.parse(localStorage.getItem("user-data"));
  const history = useHistory();
  const Button = styled.button`
    background-color: #F0F0F0;
    color: black;
    font-size: 20px;
    width: 400px;
    height: 90px;
    // padding: 20px 140px ;
    // box-shadow: 5px 7px #F0F0F0;
    box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
    border-color: #F0F0F0;
    border-radius: 10px;
    margin: 10px 90px;
    cursor: pointer;
    // &:disabled {
    //   color: grey;
    //   opacity: 0.7;
    //   cursor: default;
    @media (max-width: ${bp.desktop}) {
     margin: 5px 60px;
     padding-top: 5px;
     margin-top: 30px;
      width: 200px;
    height: 50px; }
    }
  `;
  return (
    <div>
      <Header />
      <Router>
        <div
          class="bg_image"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "contain",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            backgroundPositionX: "90%",
            backgroundPositionY: "60px",
            color: "#000000",
          }}
        >
          
          <br />
          {/* <Marquee><h3>Welcome, {username.user.FirstName} {username.user.LastName}</h3></Marquee> */}
      
          <Button onClick={() => history.push("/CourseMaterial")}>
            Course Material
          </Button>
          <br />
          <Button onClick={() => history.push("/myassign")}>
            My Assignments
          </Button>
          <br />
          <Button onClick={() => history.push("/myattend")}>
            My Attendance
          </Button>
          <br />
          <Button onClick={() => history.push("/overview")}>My Profile</Button>
          <br />
          <Button onClick={() => history.push("/settings")}>Settings</Button>
        </div>
      </Router>
    </div>
  );
}
export default Courses;
