import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import logo from "../Logos/CCBT_Logo-01.png";
import ham from "../Logos/Hamburger-toggle.jpg"
import { breakpoints as bp } from "../GlobalStyle";

const Styles = styled.div`
  // .navbar {
  //   background-color: #000000;
  //   // height: 63px;
  // }
  // .navbar-toggler-icon {
  //   color: #fff
  // }
  .ml-auto {
    margin: 5 !important;
  }
  // .basic-navbar-nav {
  //   color: #000;
  // }
  .Brand {
    padding-left: 15px;
  }
  .logo {
    padding-left: 15px;
  }
  .HeaderDrop {
    float: left;
  }
  .links {
    padding-top: 2rem;
  }
  // .navbar-brand,
  .navbar-nav .nav-link {
    color: #fff;
    padding-right: 3.5rem;
    padding-left: 1.5rem;
    &:hover {
      color: white;
      position: sticky;
    }
    @media (max-width: ${bp.mobile}) {
      // .navbar {
      //   background-color: #000;
      // }
     
    }
  }
`;

function Header() {
  const user = JSON.parse(localStorage.getItem("user-info"));
  const username = JSON.parse(localStorage.getItem("user-data"));
  if (user != null) {
    var token = user.token;
  }
  const history = useHistory();
  let tokenvalue = null;

  function getUser(tok) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${tok}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${process.env.React_App_ccbt}/getuser`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        var resultdata = result.user.name;
        var savetoken = localStorage.setItem("id", resultdata);
        tokenvalue = localStorage.getItem("id");

        var data = JSON.stringify(result);
        var savedata = localStorage.setItem("user-data", data);
        tokenvalue = localStorage.getItem("user-data");
        console.log(tokenvalue)
      })
      .catch((error) => console.log("error", error));
  }
  tokenvalue = localStorage.getItem("id");
  if (user != null) {
    getUser(token);
  }

  function logOut() {
    localStorage.clear();
    history.push("/login");
  }

  // console.log(username.user)
  // expand="lg" variant="dark"
  return (
    <div>
      <Styles>
        <Navbar expand="lg" bg="dark" variant="dark">
          <img
            alt=""
            src={logo}
            width="55"
            height="55"
            className="d-inline-block align-top"
          />
          <Navbar.Brand className="Brand" href="/courses">
            <h3>CCBT ACADEMY</h3>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto nav_bar_wrapper">
              {localStorage.getItem("user-info") ? (
                <>
                  {/* <h3>Welcome, {username.user.FirstName} {username.user.LastName}</h3> */}
                  <Nav className="links">
                    <Nav.Item>
                      <Nav.Link href="/courses">Home</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link href="./about">About</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="/home">Courses</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link href="/contact">Contact</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={logOut}>Logout</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </>
              ) : (
                <>
                  <Nav className="links">
                    <Nav.Item>
                      <Nav.Link href="/ccbt">Home</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link exact href="/coursesg">Courses</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link exact href="/login">Sign In</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </>
              )}
            </Nav>
            <Nav className="HeaderDrop">
              {localStorage.getItem("user-info") ? (
                <>
                  {/* <NavDropdown title={tokenvalue}>
                    <NavDropdown.Item href="/profile">
                      My Profile
                    </NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item onClick={logOut}>Logout</NavDropdown.Item>
                  </NavDropdown> */}
                </>
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Styles>
    </div >
  );
}
export default Header;
