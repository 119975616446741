import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const Styles = styled.div`
  .row {
    margin-left: 10px;
  }
`;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function Statshomeg() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <strong>
              <h4>Goal - All Languages- all Dialects- all God’s word</h4>
            </strong>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <strong>
              <h4>
                The Big Challenge: 1.5 billion people still don’t have a Full
                Bible
              </h4>
            </strong>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Paper className={classes.paper}>
            <h4>Languages in the world</h4>
            <h2>
              <strong>
                <CountUp duration={4} start={0} end={7394} />
              </strong>
            </h2>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Paper className={classes.paper}>
            <h4>Full Bible available</h4>
            <h2>
              <strong>
                <CountUp duration={1} start={0} end={736} />
              </strong>
            </h2>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Paper className={classes.paper}>
            <h4>NT Availability</h4>
            <h2>
              <strong>
                <CountUp duration={3} start={0} end={1658} />
              </strong>
            </h2>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Paper className={classes.paper}>
            <h4>Scripture Portions </h4>
            <h2>
              <strong>
                <CountUp duration={2} start={0} end={1264} />
              </strong>
            </h2>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            {/* <h4>For information on Bible Translations </h4>     */}
            <a
              href="https://www.wycliffe.net/resources/statistics/"
              target="_blank"
              rel="noreferrer"
            >
              <strong>
                Click here to know the latest Bible Translation statistics
              </strong>
            </a>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <strong>
              <h4>
                The Big Bottleneck : Dearth of Quality Checkers & Consultants
              </h4>
            </strong>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Statshomeg;
