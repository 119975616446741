import React, { useState, useEffect } from "react";

function GetTopics({ parentCallback }) {
  const [result, topiclist] = useState([]);
  const [topicState, setTopicState] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.React_App_ccbt}/getccbttopics`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((resp) => resp.json())
      .then((resp) => topiclist(resp.data));
  }, []);
  
  return (
    <div>
      <div>
      </div>
      <br />
      <select
        className="topic-select"
        onChange={(e) => {
          parentCallback(e.target.value);
        }}
      >
        <option disabled selected>
          -----------------Click Here for Topics!!-------------------
        </option>
        {result.map((x) => (
          <option value={x.pk_ClassTopicID}>{x.Display_topic}</option>
        ))}
        <br />
      </select>
      {topicState}
    </div>
  );
}
export default GetTopics;
