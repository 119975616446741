import React, { Component,useEffect } from "react";
import { BrowserRouter, Redirect, Route,useLocation } from "react-router-dom";
import ReactGa from 'react-ga';
import Homeg from "./guestcomponents/Homeg";
import Coursesg from "./guestcomponents/Coursesg";

import Login from "./components/Login";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Courses from "./components/Courses/CoursesH/Courses";
import Protected from "./components/Protected";
import Forgot from "./components/Forgot";
import ChangePass from "./components/ChangePass";

import CourseMaterial from "./components/Courses/CourseMaterial";
import Ebook from "./components/Courses/Course Material/Ebook";
import Ppt from "./components/Courses/Course Material/Ppt";
import Videos from "./components/Courses/Course Material/Videos";

import MyAssignments from "../src/components/Courses/MyAssignments";
import MyAttendance from "../src/components/Courses/MyAttendance";

import MyProfile from "../src/components/Courses/MyProfile";
import Overview from "./components/Courses/My Profile/Overview";
import Qualification from "./components/Courses/My Profile/Qualification";
import Linguistic from "./components/Courses/My Profile/MyQualification/Linguistic";
import BibleTranslationSw from "./components/Courses/My Profile/MyQualification/BibleTranslationSw";
import ComputerTraining from "./components/Courses/My Profile/MyQualification/ComputerTraining";
import SecularEdu from "./components/Courses/My Profile/MyQualification/SecularEdu";
import SoftwareProgs from "./components/Courses/My Profile/MyQualification/SoftwareProgs";
import TheologyEdu from "./components/Courses/My Profile/MyQualification/TheologyEdu";

import Sogc from "./components/Courses/My Profile/Sogc";
import Class from "./components/Courses/My Profile/SOGC/Class";
import Trainee from "./components/Courses/My Profile/SOGC/Trainee";
import TrAttendance from "./components/Courses/My Profile/SOGC/TrAttendance";

import CBTF from "./components/Courses/My Profile/MyCourses/CBTF";
import CCBT from "./components/Courses/My Profile/MyCourses/CCBT";
import SOGC from "./components/Courses/My Profile/MyCourses/SOGC";

import Settings from "../src/components/Courses/Settings";

function App() {
  // let location = useLocation();
useEffect(() => {
 ReactGa.initialize('UA-178389359-5')
 ReactGa.pageview(window.location.pathname + window.location.search);
}, [])

  return (
    <div className="App">
      <BrowserRouter >
        <Route path="/ccbt">
          <Homeg />
        </Route>
        <Route path="/coursesg">
          <Coursesg />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/forgot">
          <Forgot />
        </Route>
        <Route path="/changepass">
          <ChangePass />
        </Route>
        <Route path="/home">
          <Protected Cmp={Home} />
        </Route>
        <Route path="/about">
          <Protected Cmp={About} />
        </Route>
        <Route path="/courses">
          <Protected Cmp={Courses} />
        </Route>
        <Route path="/contact">
          <Protected Cmp={Contact} />
        </Route>
        <Route path="/CourseMaterial">
          <CourseMaterial />
        </Route>
        <Route path="/ebooks">
          <Ebook />
        </Route>
        <Route path="/ppt">
          <Ppt />
        </Route>
        <Route path="/videos">
          <Videos />
        </Route>
        <Route path="/myassign">
          <MyAssignments />
        </Route>
        <Route path="/myattend">
          <MyAttendance />
        </Route>
        <Route path="/myprofile">
          <MyProfile />
        </Route>
        <Route path="/overview">
          <Overview />
        </Route>
        <Route path="/qualification">
          <Qualification />
        </Route>
        <Route path="/ld">
          <Linguistic />
        </Route>
        <Route path="/sed">
          <SecularEdu />
        </Route>
        <Route path="/ted">
          <TheologyEdu />
        </Route>
        <Route path="/ct">
          <ComputerTraining />
        </Route>
        <Route path="/sp">
          <SoftwareProgs />
        </Route>
        <Route path="/bts">
          <BibleTranslationSw />
        </Route>
        <Route path="/sogc">
          <Sogc />
        </Route>
        <Route path="/class">
          <Class />
        </Route>
        <Route path="/train">
          <Trainee />
        </Route>
        <Route path="/trattend">
          <TrAttendance />
        </Route>
        <Route path="/m_cbtf">
          <CBTF />
        </Route>
        <Route path="/m_ccbt">
          <CCBT />
        </Route>
        <Route path="/m_sogc">
          <SOGC />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
      </BrowserRouter>
    </div>
  );
}

export default App;
