import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { MDBContainer,
  MDBRow,
  MDBCol,
 MDBDataTableV5 } from "mdbreact";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import MyProfile from "../../MyProfile";
import { breakpoints as bp } from "../../../../GlobalStyle";

const Button3 = styled.button`
background-color: #F0F0F0;
color: black;
font-size: 15px;
width: 90px;
height: 35px;
padding: -10px 3px 5px 0px;
// box-shadow: 5px 7px #F0F0F0;
box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
border-color: #F0F0F0;
border-radius: 10px;
margin: 5px 25px 16px 5px;
cursor: pointer;
// &:disabled {
//   color: grey;
//   opacity: 0.7;
//   cursor: default;
// @media (max-width: ${bp.desktop}) {
//  margin: 200px 25px 16px 250px;
//  padding: -10px 3px 5px 0px;
//   width: 70px;
// height: 30px; }

@media (max-width: ${bp.mobile}) {
  margin: 5px 20px 15px 5px;
  padding: 2px 3px 5px 2px;
   width: 70px;
 height: 30px; }
}
`;

export default function Trainee() {
  const history = useHistory();
  var datai = localStorage.getItem("user-data");
  var dati = JSON.parse(datai);

  const Styles = styled.div`
    /* CSS */

    #grid-container {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 200px;
      padding-left: 10px;
      float: center;
      margin-top: 10px;
    }
  `;
  
  const location = useLocation();
  // console.log(location.state);
  const id = location.state;

  const [data, setData] = useState([]);
 
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  useEffect(() => {
    fetch(
      `${process.env.React_App_ccbt}/getstutr/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json.data);
        setData(json.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const datas = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "DOB",
        field: "DOB",
        sort: "asc",
        width: 200,
      },
      {
        label: "Father Name",
        field: "Father_Name",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "FirstName",
      //   field: "FirstName",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Gender",
        field: "Gender",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "LastName",
      //   field: "LastName",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 270,
      },
      {
        label: "Mobile",
        field: "Mobile",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Whats_App_No",
      //   field: "Whats_App_No",
      //   sort: "asc",
      //   width: 200,
      // },
    ],
    rows: data.map((data) => {
      return {
        name: data.name,

        DOB: data.DOB,
        // FirstName: data.FirstName,
        // LastName: data.LastName,
        Father_Name: data.Father_Name,
        Gender: data.Gender,
        email: data.email,
        Mobile: data.Mobile,
        Whats_App_No: data.Whats_App_No,
      };
    }),
  };
  return (
    <div>
      <MyProfile />
      <body>
      <Button3 onClick={() => history.push("/sogc")}>◀ <b>Back</b></Button3>
        <Styles>
            <MDBContainer>
              <MDBRow center className="mt-3 pt-1">
                <MDBCol md="12">
                  <MDBDataTableV5 scrollX entries={7} data={datas} pagingTop searchTop searchBottom={false} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </Styles>
      </body>
    </div>
  );
}
