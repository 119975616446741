import React, { useState } from "react";
import Header from "../Header";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import {  Container, Col, Row, Button as Button2 } from "react-bootstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import bg from "../../Logos/CCBT_Logo-GreyHome-01.png";
import { breakpoints as bp } from "../../GlobalStyle";

const Styles = styled.div`
  .mr-auto {
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #link1 {
    padding-left: 24px;
  }
`;
const Button3 = styled.button`
background-color: #F0F0F0;
color: black;
font-size: 15px;
width: 90px;
height: 35px;
padding: -10px 3px 5px 0px;
// box-shadow: 5px 7px #F0F0F0;
box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
border-color: #F0F0F0;
border-radius: 10px;
margin: 5px 25px 16px 5px;
cursor: pointer;
// &:disabled {
//   color: grey;
//   opacity: 0.7;
//   cursor: default;
// @media (max-width: ${bp.desktop}) {
//  margin: 200px 25px 16px 250px;
//  padding: -10px 3px 5px 0px;
//   width: 70px;
// height: 30px; }

@media (max-width: ${bp.mobile}) {
  margin: -10px 20px 15px 5px;
  padding: 2px 3px 5px 2px;
   width: 70px;
 height: 30px; }
}
`;

export default function CourseMaterial() {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const Button = styled.button`
    background-color: #F0F0F0;
    color: black;
    font-size: 20px;
    width: 400px;
    height: 100px;
    // padding: 20px 140px ;
    // box-shadow: 5px 7px #F0F0F0;
    box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
    border-color: #F0F0F0;
    border-radius: 60px;
    margin: 25px 300px;
    cursor: pointer;
    // &:disabled {
    //   color: grey;
    //   opacity: 0.7;
    //   cursor: default;
    @media (max-width: ${bp.desktop}) {
     margin: 20px 100px;
     padding-top: 5px;
     margin-top: 30px;
      width: 200px;
    height: 90px; }
    }
  `;
  return (
    <div>
      <Header />
      <div
          class="bg_image"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "contain",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            backgroundPositionX: "90%",
            backgroundPositionY: "60px",
            color: "#000000",
          }}
        >
      <Styles>
        <Navbar color="light" light expand="md">
          <NavbarBrand to="/overview">Course Material</NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {/* <NavItem>
                <Link to="/ebooks" id="link1">
                  E-Books
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/videos" id="link1">
                  Videos
                </Link>
              </NavItem> */}
            </Nav>
            <Button3 onClick={() => history.push("/courses")}>◀ <b>Back</b></Button3>
          </Collapse>
        </Navbar>
        {/* <Button3 onClick={() => history.push("/courses")}>Back</Button3> */}
      </Styles>
      <Router>
        <Container>
          <Row>
            <Col>
              <Button onClick={() => history.push("/ebooks")}>
                E-Books
              </Button>
            </Col>
            <Col>
              <Button onClick={() => history.push("/ppt")}>
                PPT
              </Button>
            </Col>
            <Col>
              <Button onClick={() => history.push("/videos")}>
                Videos
              </Button>
            </Col>
          </Row>
        </Container>
      </Router>
      </div>
    </div>
  );
}
