import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import MyProfile from "../../MyProfile";
import { breakpoints as bp } from "../../../../GlobalStyle";

const Button3 = styled.button`
background-color: #F0F0F0;
color: black;
font-size: 15px;
width: 90px;
height: 35px;
padding: -10px 3px 5px 0px;
// box-shadow: 5px 7px #F0F0F0;
box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
border-color: #F0F0F0;
border-radius: 10px;
margin: 5px 25px 16px 5px;
cursor: pointer;
// &:disabled {
//   color: grey;
//   opacity: 0.7;
//   cursor: default;
// @media (max-width: ${bp.desktop}) {
//  margin: 200px 25px 16px 250px;
//  padding: -10px 3px 5px 0px;
//   width: 70px;
// height: 30px; }

@media (max-width: ${bp.mobile}) {
  margin: 5px 20px 15px 5px;
  padding: 2px 3px 5px 2px;
   width: 70px;
 height: 30px; }
}
`;

export default function TrAttendance() {
  const history = useHistory();
  var datai = localStorage.getItem("user-data");
  var dati = JSON.parse(datai);

const Styles = styled.div`
  /* CSS */

  #grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 200px;
    padding-left: 10px;
    float: center;
    margin-top: 10px;
  }
`;

  const location = useLocation();
  // console.log(location.state);
  const id = location.state;

  const [data, setData] = useState([]);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  useEffect(() => {
    fetch(
      `${process.env.React_App_ccbt}/getstutrclat/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json.data);
        setData(json.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const datas = {
    columns: [
      {
        label: "Full Name",
        field: "Full_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Attendance",
        field: "Present_status",
        sort: "asc",
        width: 270,
      },
    ],
    rows: data.map((data) => {
      return {
        Full_name: data.Full_name,
        Present_status: data.Present_status,
      };
    }),
  };
  return (
    <div>
      <MyProfile />
      <body>
      <Button3 onClick={() => history.push("/class")}>◀ <b>Back</b></Button3>
        <Styles>
          <div id="grid-container">
            <div class="data">
              <MDBDataTable striped bordered small data={datas} />
            </div>
          </div>
        </Styles>
      </body>
    </div>
  );
}
