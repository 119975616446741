import Header from "./Header";
import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import bg from "../Logos/LoginGraphics-01-01.png";
import { breakpoints as bp } from "../GlobalStyle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Styles = styled.div`
  .col-sm-2 {
    color: black;
  }
  .bg_image {
    padding-left: 10px;
  }
  .link_login {
    color: blue;
    margin-top: -73px;
    padding-right: 30px;
    padding-bottom: 5px;
  }
  .link_forgot {
    color: dodgerblue;
    padding-bottom: 30px;
  }
  .form {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .label {
    padding-right: 37px;
    padding-bottom: 5px;
  }
  .label {
    padding-right: 10px;
    padding-bottom: 5px;
  }
  .button_login {
    padding-left: 200px;
    borderradius: 50;
  }
  .rcorners2 {
    border-radius: 45px;
    border: 2px solid #000000;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 350px;
    height: 320px;
    margin-left: 2rem;
  }

  @media (max-width: ${bp.mobile}) {
    .rcorners2 {
      border-radius: 45px;
      border: 2px solid #000000;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 300px;
      height: 320px;
      margin-left: 0rem;
      margin-top: -1.5rem;
    }
    .link_login {
      color: blue;
      margin-top: -100px;
      padding-right: 30px;
      padding-bottom: 5px;
    }
    .link_forgot {
      color: dodgerblue;
      padding-bottom: 30px;
    }
    .button_login {
      padding-left: 50px;
      borderradius: 50;
    }
  }
`;

function About() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <Styles>
        <div
          class="bg_image"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            height: "89vh",
            color: "#000000",
          }}
        >
          <MDBContainer>
            <MDBRow center className="mt-3 pt-1">
              <MDBCol md="12">
                <h2>CCBT Academy – Church Centric Bible Translation </h2>
                <br />
                <p>
                  <b>
                    "A Leap towards capacity building towards accelerating
                    Scripture translation in the remaining Bibleless languages
                    in the World "
                  </b>
                </p>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      What is the challenge of the unfinished task of the
                      Scripture translation?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Total languages in the world – 7360
                      <br />
                      Full bible available. – 704
                      <br />
                      NT availability - 1551
                      <br />
                      Scripture portions - 1160
                      {/* <br />… Refer www.wycliffe.net */}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading}>
                      Bottlenecks for accelerating scripture translation
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      a] Paradigm issues
                      <br />
                      b] Lack of adequate number of Consultants
                      <br />
                      <br />
                      <b>Solution </b>
                      <br />
                      An evolving paradigm of Church centric Bible translation
                      is the only answer
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/* <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>
            Disabled Accordion
          </Typography>
        </AccordionSummary>
      </Accordion> */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading}>
                      What does CCBT Academy do?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Create a new paradigm in the process of Quality
                      determination
                      <br />
                      • Equipping the local churches to determine the quality of
                      their translations
                      <br />• Equipping Theologically trained Quality checkers
                      to assist the consultants like the physician assistants
                      assist doctors
                      <br />• Increasing the efficiency of consultants through
                      group consulting methodology
                      <br />• Use of technology tools for Quality assurance
                      <br />
                      <br />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading}>
                      About the Program
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      A 3 year program for Quality checkers leading to Masters
                      in Christian Ministry/Bachelors in Christian Ministry with
                      specialization in Bible Translation
                      <br />
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.heading}>
                            Course Content:
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            • Principles and practice of Bible Translation
                            <br />• Principles and practice of Church centric
                            Bible Translation
                            <br />• Principles and practice of Biblical Theology
                            <br />• Use of Tools and technology in Bible
                            Translation
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </Styles>
    </div>
  );
}

export default About;
