import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import styled from "styled-components";
import MyProfile from "../MyProfile";

export default function Overview() {
  var datai = localStorage.getItem("user-data");
  var dati = JSON.parse(datai);
  const Style = styled.div`
    .headers {
      margin-bottom: 1.5rem;
      margin-left: -0.5rem;
    }
    .container {
      float: center;
    }
    .data {
      border-bottom-width: 0px;
      border-bottom: 0px solid #dee2e6;
      border: 0px solid #dee2e6;
      padding: 0rem;
    }
    .head-cohort {
      text-align: center;
    }
  `;
  const [data, setData] = useState([]);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  useEffect(() => {
    fetch(
      `${process.env.React_App_ccbt}/getdesig/${dati.user.Pk_user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json.data);
        setData(json.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const datas = {
    columns: [
      {
        label: "Cohort Type",
        field: "CohortType",
        sort: "asc",
        width: 150,
      },
      {
        label: "Role",
        field: "Role",
        sort: "asc",
        width: 270,
      },
    ],
    rows: data.map((data) => {
      return {
        CohortType: data.CohortType,
        Role: data.Role,
      };
    }),
  };
  const pic = `https://${dati.user.Student_photo}`;

  return (
    <div>
      <MyProfile />
      <Style>
        <h1 className="headers"></h1>
        <Container className="container">
          <Row>
            <Col>
              <img src={pic} width="200" height="200"></img>
            </Col>
            <Row>
              <Col>
                <Col>
                  <h7>First Name : <b>{dati.user.FirstName}</b></h7>
                  <br />
                  <br />
                  <h7>Last Name : <b>{dati.user.LastName}</b></h7>
                  <br />
                  <br />
                  <h7>Married : <b></b></h7>
                  <br />
                  <br />
                  <h7>Email ID : <b>{dati.user.email}</b></h7>
                </Col>
              </Col>
            </Row>
            <Col>
              <br />
              <br />
            </Col>
            <Row>
              <Col>
                <Col>
                  <br />
                  <h7>Date of Birth : <b>{dati.user.DOB}</b></h7>
                  <br />
                  <br />
                  <h7>Gender : <b>{dati.user.Gender}</b></h7>
                  <br />
                  <br />
                  <h7>Aadhar No : <b></b></h7>
                  <br />
                  <br />
                  <h7>Mobile No : <b>{dati.user.Mobile}</b></h7>
                </Col>
              </Col>
            </Row>
          </Row>
          <br />
          <Row>
            <Col>
              <h7>
                Present Address : <b>{dati.user.Present_address_street}</b>
                {", "}
                <b>{dati.user.Present_address_village}</b>
                {", "}
                <b>{dati.user.Present_address_city}</b>
                {", "}
                <b>{dati.user.Present_address_district}</b>
                {", "}
                <b>{dati.user.Present_address_state}</b>
                {", "}
                <b>{dati.user.Present_address_country}</b>
              </h7>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <Container>
          <Row>
            <Col>
              <h7>
                Permanent Address : <b>{dati.user.Permanent_address_street}</b>
                {", "}
                <b>{dati.user.Permanent_address_village}</b>
                {", "}
                <b>{dati.user.Permanent_address_city}</b>
                {", "}
                <b>{dati.user.Permanent_address_district}</b>
                {", "}
                <b>{dati.user.Permanent_address_state}</b>
                {", "}
                <b>{dati.user.Permanent_address_country}</b>
              </h7>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <br />
              <br />
              <h7>EMERGENCY CONTACT</h7>
              <br />
              <br />
              <h7>Contact Name : <b></b></h7>
              <br />
              <br />
              <h7>Contact No : <b></b></h7>

              <br />
              <br />
              <h7>Contact Relation : <b></b></h7>
              <br />
              <br />
              <h7>Email : <b></b></h7>
            </Col>
            <br />
            <br />
            <Row></Row>
            <Row>
              <Col>
                <h6 className="head-cohort"><b>Cohort Details</b></h6>
                <div class="data">
                  <MDBDataTable striped bordered small data={datas} />
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
      </Style>
    </div>
  );
}
