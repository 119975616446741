import React, { useState, useEffect } from "react";
import Header from "../Header";
import { Button } from "react-bootstrap";
import { MDBContainer,
  MDBRow,
  MDBCol, 
  MDBDataTableV5 } from "mdbreact";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { breakpoints as bp } from "../../GlobalStyle";

const Styles = styled.div`
  .mr-auto {
    padding-left: 15px;
  }
  .per_attend {
    margin-left: 3.5rem;
  }
`;
const Button3 = styled.button`
background-color: #F0F0F0;
color: black;
font-size: 15px;
width: 90px;
height: 35px;
padding: -10px 3px 5px 0px;
// box-shadow: 5px 7px #F0F0F0;
box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
border-color: #F0F0F0;
border-radius: 10px;
margin: 5px 25px 16px 25px;
cursor: pointer;
// &:disabled {
//   color: grey;
//   opacity: 0.7;
//   cursor: default;
// @media (max-width: ${bp.desktop}) {
//  margin: 200px 25px 16px 250px;
//  padding: -10px 3px 5px 0px;
//   width: 70px;
// height: 30px; }

@media (max-width: ${bp.mobile}) {
  margin: 5px 20px 15px 5px;
  padding: 2px 3px 5px 2px;
   width: 70px;
 height: 30px; }
}
`;

function MyAttendance() {
  const history = useHistory();
  var datai = localStorage.getItem("user-data");
  var dati = JSON.parse(datai);

  const [data, setData] = useState([]);
  const [per, setPer] = useState([]);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    headers: myHeaders,

    redirect: "follow",
  };
  useEffect(() => {
    fetch(
      `${process.env.React_App_ccbt}/getcoatt/${dati.user.Pk_user_id}`
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json.data);
        setData(json.data);
        setPer(json.cent);

      }
      
      )
      .catch((error) => console.log("error", error));
  }, []);

  const datas = {
    columns: [
      {
        label: "Date",
        field: "Date_a",
        sort: "asc",
        width: 150,
      },
      {
        label: "Topic",
        field: "Topic_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Attendance",
        field: "Present_status",
        sort: "asc",
        width: 200,
      },
    ],
    rows: data.map((data) => {
      return {
        Date_a: data.Date_a,
        Topic_name: data.Topic_name,
        Present_status: data.Present_status == "Present" ? <i>✔</i> : <i>❌</i>,
      };
    }),
  };
 
  return (
    <div>
      <Header />
      <Styles>
        <div id="grid-container">
          <div id="box-1">
            <Button3 onClick={() => history.push("/courses")}>◀ <b>Back</b></Button3>
          </div>
          <div class="data">
            <div class="per_attend">
              <Marquee speed="70"><h3><b>My Attendance is {per}%</b></h3></Marquee>
            </div>
          <MDBContainer>
              <MDBRow center className="mt-3 pt-1">
                <MDBCol md="12">
                  {/* <MDBDataTableV5 scrollX entries={7} data={datas} pagingTop searchTop searchBottom={false} /> */}
                  <MDBDataTableV5 scrollX entries={7} data={datas} pagingTop searchTop searchBottom={false} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </div>
      </Styles>
    </div>
  );
}
export default MyAttendance;
