import React from "react";
import Header from "./Header";

import { Carousel } from "react-bootstrap";
function Home() {
  return (
    <div>
      <Header />
      <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ccbtacademy.org/wp-content/uploads/2021/02/MG_8697-scaled.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3></h3>
           
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ccbtacademy.org/wp-content/uploads/2021/02/MG_8631-scaled.jpg"
            alt="Second slide"
          />

          <Carousel.Caption>
            <h3></h3>
           
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ccbtacademy.org/wp-content/uploads/2021/02/MG_7626-scaled.jpg"
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3></h3>
          
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ccbtacademy.org/wp-content/uploads/2021/02/MG_8697-scaled.jpg"
            alt="Fourth slide"
          />

          <Carousel.Caption>
            <h3></h3>
          
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ccbtacademy.org/wp-content/uploads/2021/02/MG_8631-scaled.jpg"
            alt="Fifth slide"
          />

          <Carousel.Caption>
            <h3></h3>
           
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ccbtacademy.org/wp-content/uploads/2021/02/MG_7626-scaled.jpg"
            alt="Sixth slide"
          />

          <Carousel.Caption>
            <h3></h3>
           
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <h1>WELCOME TO CCBT ACADEMY</h1>
      <p>
       
      </p>
    </div>
  );
}
export default Home;
