import React, { useState, useEffect } from "react";
import { MDBContainer,
  MDBRow,
  MDBCol,
 MDBDataTableV5 } from "mdbreact";
import styled from "styled-components";
import MyProfile from "../../MyProfile";

export default function SoftwareProgs() {
  var datai = localStorage.getItem("user-data");
  var dati = JSON.parse(datai);
  const Styles = styled.div`
    /* CSS */

    #grid-container {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 200px;
      padding-left: 10px;
      float: center;
      margin-top: 10px;
    }
  `;
 
  const [data, setData] = useState([]);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,

    redirect: "follow",
  };
  useEffect(() => {
    fetch(
      `${process.env.React_App_ccbt}/getsoftpro/${dati.user.Pk_user_id}`
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json.data);
        setData(json.data);
      })
      .catch((error) => console.log("error", error));
  }, []);
  const datas = {
    columns: [
      {
        label: "Program name",
        field: "Program_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Level",
        field: "Level",
        sort: "asc",
        width: 270,
      },
      {
        label: "Attachment",
        field: "Attachement",
        sort: "asc",
        width: 200,
      },
    ],
    rows: data.map((data) => {
      return {
        Program_name: data.Program_name,
        Level: data.Level,
        Attachement: data.Attachement,
      };
    }),
  };
  return (
    <div>
      <MyProfile />
      <h1></h1>
      <body>
        <Styles>
        <MDBContainer>
              <MDBRow center className="mt-3 pt-1">
                <MDBCol md="12">
                  <MDBDataTableV5 scrollX entries={7} data={datas} pagingTop searchTop searchBottom={false} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </Styles>
      </body>
    </div>
  );
}
