import React, { useState } from "react";
import Header from "../Header";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";
import { Button as Button2} from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { breakpoints as bp } from "../../GlobalStyle";

const Styles = styled.div`
  .mr-auto {
    padding-left: 15px;
    padding-right: 15px;
  }
  #link1 {
    padding-left: 30px;
    padding-right: 15px;
    padding-top: 0px;
    color: #000000
  }
  #link-ov {
    padding-left: 30px;
    color: #000000
  }
  .brand{
    margin-top: -10px;
  }
`;
const Button3 = styled.button`
background-color: #F0F0F0;
color: black;
font-size: 15px;
width: 90px;
height: 35px;
padding: -10px 3px 5px 0px;
// box-shadow: 5px 7px #F0F0F0;
box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
border-color: #F0F0F0;
border-radius: 10px;
margin: 5px 25px 16px 5px;
cursor: pointer;
// &:disabled {
//   color: grey;
//   opacity: 0.7;
//   cursor: default;
// @media (max-width: ${bp.desktop}) {
//  margin: 200px 25px 16px 250px;
//  padding: -10px 3px 5px 0px;
//   width: 70px;
// height: 30px; }

@media (max-width: ${bp.mobile}) {
  margin: -10px 20px 15px 5px;
  padding: 2px 3px 5px 2px;
   width: 70px;
 height: 30px; }
}
`;

function MyProfile() {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Header />
      <Styles>
        <Navbar color="light" light expand="md">
          <NavbarBrand className="brand">My Profile</NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <Link to="/overview" id="link-ov">
                  Overview
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/sogc" id="link1">
                  SOGC
                </Link>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle id="link1" nav caret>
                  My Qualifications
                </DropdownToggle>
                <DropdownMenu right>
                  <Link id="link1" to="/ld">
                    Linguistics
                  </Link>
                  <br />
                  <Link id="link1" to="/sed">
                    Secular
                  </Link>
                  <br />
                  <Link id="link1" to="/ted">
                    Theological
                  </Link>
                  <br />
                  <Link id="link1" to="/ct">
                    Computer Training
                  </Link>
                  <br />
                  <Link id="link1" to="/sp">
                    Software Programs
                  </Link>
                  <br />
                  <Link id="link1" to="/bts">
                    Bible Softwares
                  </Link>
                  <DropdownItem divider />
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle id="link1" nav caret>
                  My Courses
                </DropdownToggle>
                <DropdownMenu right>
                  <Link id="link1" to="/m_cbtf">
                    CBTF
                  </Link>
                  <br />
                  <Link id="link1" to="/m_ccbt">
                    CCBT
                  </Link>
                  <br />
                  <Link id="link1" to="/m_sogc">
                    SOGC
                  </Link>

                  <DropdownItem divider />
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Button3 onClick={() => history.push("/courses")}>◀ <b>Back</b></Button3>
          </Collapse>
        </Navbar>
      </Styles>
    </div>
  );
}
export default MyProfile;
