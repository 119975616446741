import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Header";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { MDBContainer,
  MDBRow,
  MDBCol,
 MDBDataTableV5 } from "mdbreact";
import { breakpoints as bp } from "../../GlobalStyle";

const Styles = styled.div`
  .mr-auto {
    padding-left: 15px;
  }
  #link1 {
    padding-left: 24px;
  }
`;
const Button3 = styled.button`
background-color: #F0F0F0;
color: black;
font-size: 15px;
width: 90px;
height: 35px;
padding: -10px 3px 5px 0px;
// box-shadow: 5px 7px #F0F0F0;
box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
border-color: #F0F0F0;
border-radius: 10px;
margin: 5px 25px 16px 25px;
cursor: pointer;
// &:disabled {
//   color: grey;
//   opacity: 0.7;
//   cursor: default;
// @media (max-width: ${bp.desktop}) {
//  margin: 200px 25px 16px 250px;
//  padding: -10px 3px 5px 0px;
//   width: 70px;
// height: 30px; }

@media (max-width: ${bp.mobile}) {
  margin: 5px 20px 15px 5px;
  padding: 2px 3px 5px 2px;
   width: 70px;
 height: 30px; }
}
`;

let glob = "";
function MyAssignments() {
  const history = useHistory();
  var datai = localStorage.getItem("user-data");
  var dati = JSON.parse(datai);

  const [data, setData] = useState([]);
  const [glo, setGlo] = useState([]);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    headers: myHeaders,

    redirect: "follow",
  };
  useEffect(() => {
    fetch(
      `${process.env.React_App_ccbt}/getcoatt/${dati.user.Pk_user_id}`
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json.data);
        setData(json.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const refresh = (e) => {
    e.preventDefault();
    glob = e.target.files[0];
  };
 
  const submitForm = (id) => {
    const formData = new FormData();
    formData.append("media", glob);

    setGlo(glob);
    var requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };
    fetch(
      `${process.env.React_App_ccbt}/upload/aws/${id}`,
      requestOptions
    )
      .then((response) => response.text())
      // .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    alert("File Uploaded!");
  };
  const datas = {
    columns: [
      {
        label: "Date",
        field: "Date_a",
        sort: "asc",
        width: 150,
      },
      {
        label: "Cohort Type",
        field: "Cohort_type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Session",
        field: "Session",
        sort: "asc",
        width: 150,
      },
      {
        label: "Topic",
        field: "Topic_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Status",
        field: "Assignment",
        sort: "asc",
        width: 200,
      },
      {
        label: "View",
        field: "View",
        sort: "asc",
        width: 170,
      },
      
      {
        label: "Upload",
        field: "Attachment",
        sort: "asc",
        width: 330,
      },
      {
        label: "Grade",
        field: "Grade",
        sort: "asc",
        width: 120,
      },      
    ],
    rows: data.map((data) => {
      return {
        Date_a: data.Date_a,
        Cohort_type:data.Cohort_type,
        Session:data.Session,
        Topic_name: data.Topic_name,
        Assignment: data.Assignment,
        Grade: data.Grade,
        Attachment: (
          data.Assignment == "Evaluated" ? 
          (
            <div>
            <input disabled type="file" onChange={refresh} />
            <button
              disabled onClick={submitForm.bind(null, data.Pk_cohort_attendance_id)}
            >
              Upload!
            </button>
          </div>
          ) : 
          (
            <div>
            <input type="file" onChange={refresh} />
            <button
              onClick={submitForm.bind(null, data.Pk_cohort_attendance_id)}
            >
              Upload!
            </button>
          </div>
          )
          
        ),
        View: (
          data.Assignment == "No assignment" ? 
          (
            <p>View</p>
          ) : 
          (
            <a href={ data.Attachment} target="_blank">
            View
          </a>
          )
          
        ), 
      };
    }),
  };
  return (
    <div>
      <Header />
      <body>
        <Styles>
          <div id="grid-container">
            <div id="box-1">
              <Button3 onClick={() => history.push("/courses")}>◀ <b>Back</b></Button3>
            </div>
            <div class="data">
            <MDBContainer>
              <MDBRow center className="mt-3 pt-1">
                <MDBCol md="12">
                  <MDBDataTableV5 scrollX entries={7} data={datas} pagingTop searchTop searchBottom={false} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            </div>
          </div>
        </Styles>
      </body>
      <div></div>
    </div>
  );
}
export default MyAssignments;
