import React, { useState, useEffect } from "react";
import { MDBContainer,
  MDBRow,
  MDBCol,
 MDBDataTableV5 } from "mdbreact";
import { Button } from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import MyProfile from "../../MyProfile";
import { breakpoints as bp } from "../../../../GlobalStyle";

const Button3 = styled.button`
background-color: #F0F0F0;
color: black;
font-size: 15px;
width: 90px;
height: 35px;
padding: -10px 3px 5px 0px;
// box-shadow: 5px 7px #F0F0F0;
box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
border-color: #F0F0F0;
border-radius: 10px;
margin: 5px 25px 16px 5px;
cursor: pointer;
// &:disabled {
//   color: grey;
//   opacity: 0.7;
//   cursor: default;
// @media (max-width: ${bp.desktop}) {
//  margin: 200px 25px 16px 250px;
//  padding: -10px 3px 5px 0px;
//   width: 70px;
// height: 30px; }

@media (max-width: ${bp.mobile}) {
  margin: 5px 20px 15px 5px;
  padding: 2px 3px 5px 2px;
   width: 70px;
 height: 30px; }
}
`;

export default function Class() {
  const history = useHistory();
  var datai = localStorage.getItem("user-data");
  var dati = JSON.parse(datai);

  const Styles = styled.div`
    /* CSS */

    #grid-container {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 165px;
      padding-left: 10px;
      float: center;
      margin-top: 10px;
    }
  `;
  
  const location = useLocation();
  // console.log(location.state);
  const id = location.state;

  const [data, setData] = useState([]);
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  useEffect(() => {
    fetch(
      `${process.env.React_App_ccbt}/getstutrcl/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json.data);
        setData(json.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const datas = {
    columns: [
      {
        label: "Date",
        field: "Date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Topic",
        field: "Topic_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Taught by",
        field: "Taught_by",
        sort: "asc",
        width: 200,
      },
      {
        label: "Check Attendance",
        field: "Check_Attendance",
        sort: "asc",
        width: 200,
      },
    ],
    rows: data.map((data) => {
      return {
        Date: data.Date,
        Topic_name: data.Topic_name,
        Taught_by: data.Taught_by,
        Check_Attendance: (
          <Link
            to={{
              pathname: "/trattend",
              state: data.Pk_studentcohortclassid,
            }}
          >
            See Attendance ⇒
          </Link>
        ),
      };
    }),
  };
  return (
    <div>
      <MyProfile />
      <body>
      <Button3 onClick={() => history.push("/sogc")}>◀ <b>Back</b></Button3>
        <Styles>
            <MDBContainer>
              <MDBRow center className="mt-3 pt-1">
                <MDBCol md="12">
                  <MDBDataTableV5 scrollX entries={7} data={datas} pagingTop searchTop searchBottom={false} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </Styles>
      </body>
    </div>
  );
}
