import React, { useState, useEffect } from "react";
import { MDBContainer,
  MDBRow,
  MDBCol,
 MDBDataTableV5 } from "mdbreact";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MyProfile from "../MyProfile";

export default function Sogc() {
  var datai = localStorage.getItem("user-data");
  var dati = JSON.parse(datai);

  const Styles = styled.div`
    /* CSS */

    #grid-container {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 0px;
      padding-left: 17px;
      float: center;
      margin-top: 10px;
    }
  `;
  
  const [data, setData] = useState([]);
 
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,

    redirect: "follow",
  };
  useEffect(() => {
    fetch(
      `${process.env.React_App_ccbt}/getstuco/${dati.user.Pk_user_id}`
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json.data);
        setData(json.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const datas = {
    columns: [
      {
        label: "Location",
        field: "Cohort_location",
        sort: "asc",
        width: 150,
      },
      {
        label: "Cohort Type",
        field: "Cohort_type",
        sort: "asc",
        width: 270,
      },
      {
        label: "Language",
        field: "Language",
        sort: "asc",
        width: 200,
      },
      {
        label: "State",
        field: "State",
        sort: "asc",
        width: 200,
      },
      {
        label: "District",
        field: "District",
        sort: "asc",
        width: 200,
      },
      {
        label: "Training",
        field: "Training",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 200,
      },
      {
        label: "No. of Trainees",
        field: "Total_trainee",
        sort: "asc",
        width: 200,
      },
      {
        label: "Trainee Details",
        field: "Trainee_details",
        sort: "asc",
        width: 200,
      },
    ],
    rows: data.map((data) => {
      return {
        Cohort_location: (
          <Link
            to={{
              pathname: "/class",
              state: data.Pk_studentcohortid,
            }}
          >
            {data.Cohort_location}
          </Link>
        ),
    
        Cohort_type: data.Cohort_type,
        Language: data.Language,
        State: data.State,
        District: data.District,
        Training: data.Training,
        Status: data.Status,
        Total_trainee: data.Total_trainee,
        Trainee_details: (
          <Link
            to={{
              pathname: "/train",
              state: data.Pk_studentcohortid,
            }}
          >
            Go to details
          </Link>
        ),
      };
    }),
  };

  return (
    <div>
      <MyProfile />
      <body>
        <Styles>
            <MDBContainer>
              <MDBRow center className="mt-1 pt-2">
                <MDBCol md="12">
                  <MDBDataTableV5 scrollX entries={7} data={datas} pagingTop searchTop searchBottom={false} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </Styles>
      </body>
    </div>
  );
}
