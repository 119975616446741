import React from "react";
import MyProfile from "../../MyProfile";

export default function CBTF() {
  return (
    <div>
      <MyProfile />
      <h1> My Courses- CBTF</h1>
    </div>
  );
}
