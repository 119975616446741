import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Statshomeg from "./HomeG/Statshomeg";
import Botthomeg from "./HomeG/Botthomeg";
import Dohomeg from "./HomeG/Dohomeg";
import img1 from "./Images/Bible3.jpg";
import img2 from "./Images/gray.jpg";
import img3 from "./Images/oldman_bible.jpg";
import { breakpoints as bp } from "../GlobalStyle";

const Styles = styled.div`
  @import url(https://fonts.googleapis.com/css?family=Expletus+Sans:600);
  /*==========SCALE-IN==========*/
  @-webkit-keyframes scale-in {
    0% {
      transform: scale(10, 10);
      opacity: 0;
    }
    100% {
      transform: scale(1, 1);
      opacity: 1;
    }
  }

  @keyframes scale-in {
    0% {
      transform: scale(10, 10);
      opacity: 0;
    }
    100% {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
  /*==========ROLL-OUT==========*/
  @-webkit-keyframes roll-out {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes roll-out {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  body {
    background: #392f28;
  }
  main {
    width: 100%;
    overflow: hidden;
    font-family: "Expletus Sans", cursive;
  }
  .hero {
    width: 100%;
    max-width: 1550px;
    height: 438px;
    text-align: center;
    margin: 0 auto;
    background: #392f28;
    position: relative;
    color: #ffffff;
  }

  .hero h1 {
    font-size: 4.1em;
    font-family: "Lucida Console", "Courier New", monospace;
    margin: auto;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .hero h2 {
    font-size: 3.1em;
    font-family: "Times New Roman", Times, serif;
    margin: auto;
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .hero p {
    font-size: 1.5em;
    //   font-family: 'Expletus Sans', cursive;
    font-family: "Lucida Console", "Courier New", monospace;
    margin: auto;
    position: absolute;
    top: 300px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .hero img {
    width: 100%;
    height: 100%;
  }
  .bar {
    width: 100%;
    height: 250px;
    //   background: -moz-linear-gradient(289deg, #c60a09 0%, #f23332 36%, #c60a09 100%);
    //   background: -webkit-linear-gradient(289deg, #c60a09 0%, #f23332 36%, #c60a09 100%);
    //   background: -ms-linear-gradient(289deg, #c60a09 0%, #f23332 36%, #c60a09 100%);
    //   background: linear-gradient(161deg, #c60a09 0%, #f23332 36%, #c60a09 100%);
    ///
    background: -moz-linear-gradient(
      289deg,
      #000000 0%,
      #000000 36%,
      #c60a09 100%
    );
    background: -webkit-linear-gradient(
      289deg,
      #000000 0%,
      #000000 36%,
      #c60a09 100%
    );
    background: -ms-linear-gradient(
      289deg,
      #000000 0%,
      #000000 36%,
      #c60a09 100%
    );
    background: linear-gradient(161deg, #000000 0%, #000000 36%, #c60a09 100%);
    opacity: 0.3;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 50px;
    -ms-transform-origin: 100% 0%;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-animation: roll-out 1.2s ease-out;
    -moz-animation: roll-out 1.2s ease-out;
    animation: roll-out 1.2s ease-out;
  }
  .hero h1,
  .hero p {
    -webkit-animation: scale-in 1.3s ease-out;
    -moz-animation: scale-in 1.3s ease-out;
    animation: scale-in 1.3s ease-out;
  }

  @media (max-width: ${bp.mobile}) {
    .hero {
      height: 50vw;
    }
    .bar {
      height: 30vw;
      top: 2vw;
    }
    .hero h1 {
      font-size: 8vw;
      font-family: "Lucida Console", "Courier New", monospace;
      top: 8vw;
    }
    .hero h2 {
      font-size: 6vw;
      font-family: "Times New Roman", Times, serif;
      top: 18vw;
    }
    .hero p {
      font-size: 4vw;
      font-family: "Expletus Sans", cursive;
      // font-family: "Lucida Console", "Courier New", monospace;
      top: 32vw;
    }
  }
`;

export default function Homeg() {
  return (
    <div>
      <Header />
      <Styles>
        <div class="hero">
          <h1>CCBT Academy</h1>
          <h2>Church Centric Bible Translation</h2>
          {/* <h2>Church Centric Bible Translation</h2> */}
          <p>
            “ A Leap towards capacity building towards accelerating Scripture
            translation in the remaining Bibleless languages in the World “{" "}
          </p>
          <img src={img2} />
          {/* "https://40.media.tumblr.com/f2916759544c106d91c28a9e0fd2cb94/tumblr_n7qhrzxUiv1sfie3io1_1280.jpg" */}
          <div class="bar"></div>
        </div>
        <Statshomeg />
        <Botthomeg />
        <Dohomeg />
      </Styles>
    </div>
  );
}
