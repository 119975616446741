import React from "react";
import styled from "styled-components";
import img1 from "../Images/BG1.jpg";
import { breakpoints as bp } from "../../GlobalStyle";

const Styles = styled.div`
  @import url(https://fonts.googleapis.com/css?family=Expletus+Sans:600);
  /*==========SCALE-IN==========*/
  @-webkit-keyframes scale-in {
    0% {
      transform: scale(10, 10);
      opacity: 0;
    }
    100% {
      transform: scale(1, 1);
      opacity: 1;
    }
  }

  @keyframes scale-in {
    0% {
      transform: scale(10, 10);
      opacity: 0;
    }
    100% {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
  /*==========ROLL-OUT==========*/
  @-webkit-keyframes roll-out {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes roll-out {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  body {
    background: #392f28;
  }
  main {
    width: 100%;
    overflow: hidden;
    font-family: "Expletus Sans", cursive;
  }
  .hero2 {
    width: 100%;
    max-width: 1550px;
    height: 400px;
    text-align: center;
    margin: 0 auto;
    background: #392f28;
    position: relative;
    color: #ffffff;
  }

  .hero2 h1 {
    font-size: 4.1em;
    font-family: "Lucida Console", "Courier New", monospace;
    margin: auto;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .hero2 h2 {
    font-size: 2.6em;
    font-family: "Times New Roman", Times, serif;
    margin: auto;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .hero2 p {
    font-size: 1.2em;
    //   font-family: 'Expletus Sans', cursive;
    font-family: "Lucida Console", "Courier New", monospace;
    margin: auto;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .hero2 img {
    width: 100%;
    height: 100%;
  }
  .bar2 {
    width: 100%;
    height: 50px;
    //   background: -moz-linear-gradient(289deg, #c60a09 0%, #f23332 36%, #c60a09 100%);
    //   background: -webkit-linear-gradient(289deg, #c60a09 0%, #f23332 36%, #c60a09 100%);
    //   background: -ms-linear-gradient(289deg, #c60a09 0%, #f23332 36%, #c60a09 100%);
    //   background: linear-gradient(161deg, #c60a09 0%, #f23332 36%, #c60a09 100%);
    ///
    background: -moz-linear-gradient(
      289deg,
      #000000 0%,
      #000000 36%,
      #c60a09 100%
    );
    background: -webkit-linear-gradient(
      289deg,
      #000000 0%,
      #000000 36%,
      #c60a09 100%
    );
    background: -ms-linear-gradient(
      289deg,
      #000000 0%,
      #000000 36%,
      #c60a09 100%
    );
    background: linear-gradient(161deg, #000000 0%, #000000 36%, #c60a09 100%);
    opacity: 0.5;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 20px;
    -ms-transform-origin: 100% 0%;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-animation: roll-out 1.2s ease-out;
    -moz-animation: roll-out 1.2s ease-out;
    animation: roll-out 1.2s ease-out;
  }
  .bar3 {
    width: 100%;
    height: 70px;
    background: -moz-linear-gradient(
      289deg,
      #000000 0%,
      #000000 36%,
      #c60a09 100%
    );
    background: -webkit-linear-gradient(
      289deg,
      #000000 0%,
      #000000 36%,
      #c60a09 100%
    );
    background: -ms-linear-gradient(
      289deg,
      #000000 0%,
      #000000 36%,
      #c60a09 100%
    );
    background: linear-gradient(161deg, #000000 0%, #000000 36%, #c60a09 100%);
    opacity: 0.8;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 80px;
    -ms-transform-origin: 100% 0%;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-animation: roll-out 1.2s ease-out;
    -moz-animation: roll-out 1.2s ease-out;
    animation: roll-out 1.2s ease-out;
  }
  .bar4 {
    width: 100%;
    height: 210px;
    // background: -moz-linear-gradient(289deg, #000000 0%, #000000 36%, #c60a09 100%);
    // background: -webkit-linear-gradient(289deg, #000000 0%, #000000 36%, #c60a09 100%);
    // background: -ms-linear-gradient(289deg, #000000 0%, #000000 36%, #c60a09 100%);
    // background: linear-gradient(161deg, #000000 0%, #000000 36%, #c60a09 100%);
    background: -moz-linear-gradient(
      289deg,
      #000000 50%,
      #000000 36%,
      #000000 100%
    );
    background: -webkit-linear-gradient(
      289deg,
      #000000 50%,
      #000000 36%,
      #000000 100%
    );
    background: -ms-linear-gradient(
      289deg,
      #000000 50%,
      #000000 36%,
      #000000 100%
    );
    background: linear-gradient(161deg, #000066 20%, #000000 36%, #000066 90%);
    opacity: 0.9;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 160px;
    -ms-transform-origin: 100% 0%;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-animation: roll-out 1.2s ease-out;
    -moz-animation: roll-out 1.2s ease-out;
    animation: roll-out 1.2s ease-out;
  }
  .hero2 h1,
  .hero p {
    -webkit-animation: scale-in 1.3s ease-out;
    -moz-animation: scale-in 1.3s ease-out;
    animation: scale-in 1.3s ease-out;
  }
  .bar4 p {
    top: 4rem;
  }

  @media (max-width: ${bp.mobile}) {
    .hero2 {
      height: 50vw;
    }
    .bar2 {
      height: 6vw;
      top: 2vw;
    }
    .bar3 {
      height: 6vw;
      top: 9vw;
    }

    .bar3 p {
      font-size: 2vw;
      top: -0.9vw !important;
    }
    .bar4 {
      height: 32vw;
      top: 16vw;
    }
    .bar4 h3 {
      font-size: 4vw;
      top: 12vw;
    }
    .bar4 p {
      font-size: 2vw;
      top: 12vw !important;
    }
    .hero2 h1 {
      font-size: 5vw;
      font-family: "Lucida Console", "Courier New", monospace;
      top: 3vw;
    }
    .hero2 h2 {
      font-size: 4vw;
      font-family: "Times New Roman", Times, serif;
      top: 2vw;
    }
    .hero2 p {
      font-size: 2.7vw;
      // font-family: 'Expletus Sans', cursive;
      font-family: "Times New Roman", Times, serif;
      top: 2vw;
    }
  }
`;

export default function Dohomeg() {
  return (
    <Styles>
      <div class="hero2">
        <img src={img1} />
        <h2>What do we provide?</h2>
        <div class="bar2"></div>

        <div class="bar3">
          <p>
            <b>
              An accredited Bachelors/Masters program in Bible Translation with
              Practical training & mentoring.
            </b>
          </p>
        </div>

        <div class="bar4">
          <h3>The Big Solution calls for a radically new paradigm</h3>
          <br />
          <p>
            ✔ Equipping Local Churches to do their own Translation
            <br />
            ✔ Equipping local churches to train their own Quality Checkers &
            Consultants
            <br />✔ Equipping local churches with adequate tools & resources
          </p>
        </div>
      </div>
    </Styles>
  );
}
