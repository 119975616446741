import React from "react";
import Header from "../Header";
import { Button, Card } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { breakpoints as bp } from "../../GlobalStyle";

const Styles = styled.div`
  .settings {
    height: 15rem;
    width: 20rem;
    margin-left: 20%;
  }
`;
const Button3 = styled.button`
background-color: #F0F0F0;
color: black;
font-size: 15px;
width: 90px;
height: 35px;
padding: -10px 3px 5px 0px;
// box-shadow: 5px 7px #F0F0F0;
box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
border-color: #F0F0F0;
border-radius: 10px;
margin: 5px 25px 16px 5px;
cursor: pointer;
// &:disabled {
//   color: grey;
//   opacity: 0.7;
//   cursor: default;
// @media (max-width: ${bp.desktop}) {
//  margin: 200px 25px 16px 250px;
//  padding: 5px 3px 5px 0px;
//   width: 70px;
// height: 30px; }

@media (max-width: ${bp.mobile}) {
  margin: 5px 20px 15px 5px;
  padding: 2px 3px 5px 2px;
   width: 70px;
 height: 30px; }
}
`;

function Settings() {
  const history = useHistory();
  var data = localStorage.getItem("user-data");
  var dat = JSON.parse(data);

  return (
    <div>
      <Header />
      <Button3 onClick={() => history.push("/courses")}>◀ <b>Back</b></Button3>
      <Styles>
        <Card className="settings">
          <Card.Body>
            <Card.Title>Settings</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {dat.user.FirstName} {dat.user.LastName}
            </Card.Subtitle>
            <Card.Text>
              {dat.user.email}
              <br />
              <br />
            </Card.Text>

            <Link to={"/forgot"}>Change Password</Link>
          </Card.Body>
        </Card>
      </Styles>
     
    </div>
  );
}
export default Settings;
