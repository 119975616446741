import React, { useState } from "react";
import Header from "../../Header";
import { Button } from "react-bootstrap";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import styled from "styled-components";
import GetTopics from "./GetTopics";
import { useHistory } from "react-router-dom";
import { breakpoints as bp } from "../../../GlobalStyle";

const Styles = styled.div`
  .mr-auto {
    padding-left: -15px;
    padding-bottom: 10px;
  }
  #link1 {
    padding-left: 60px;
    padding-bottom: 10px;
  }
`;
const Button3 = styled.button`
background-color: #F0F0F0;
color: black;
font-size: 15px;
width: 90px;
height: 35px;
padding: -10px 3px 5px 0px;
// box-shadow: 5px 7px #F0F0F0;
box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
border-color: #F0F0F0;
border-radius: 10px;
margin: 5px 25px 16px 5px;
cursor: pointer;
// &:disabled {
//   color: grey;
//   opacity: 0.7;
//   cursor: default;
// @media (max-width: ${bp.desktop}) {
//  margin: 200px 25px 16px 250px;
//  padding: -10px 3px 5px 0px;
//   width: 70px;
// height: 30px; }

@media (max-width: ${bp.mobile}) {
  margin: -10px 20px 15px 5px;
  padding: 2px 3px 5px 2px;
   width: 70px;
 height: 30px; }
}
`;

export default function Videos() {
  const [result, link] = useState([]);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const callback = (count) => {
    // do something with value in parent component, like save to state
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${process.env.React_App_ccbt}/getccbtres/${count}/videos`,
      requestOptions
    )
      .then((response) => response.json())
      .then((resp) => link(resp.data[0].URL))
      .then((resp) => console.log(resp.data[0].URL))
      .catch((error) => console.log("error", error));
  };
  return (
    <div>
      <Header />
      <Styles>
        <Navbar color="light" light expand="md">
          <NavbarBrand to="/overview">Videos</NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                {/* <GetTopics parentCallback={callback} /> */}
              </NavItem>
            </Nav>
            <Button3 onClick={() => history.push("/CourseMaterial")}>
              ◀ <b>Back</b>
            </Button3>
          </Collapse>
        </Navbar>
      </Styles>

      <GetTopics parentCallback={callback} />
      <div>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            src={result}
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  );
}
