import React, { useState, useEffect } from "react";
import {
  Link,
  useHistory,
} from "react-router-dom";
import Header from "./Header";
import styled from "styled-components";
import { useForm } from "react-hook-form";
// import bg from "../Logos/LoginGraphics-01-01.png";
// import bg from "../guestcomponents/Images/oldman_bible.jpg";  
import bgd from "../guestcomponents/Images/oldlady_bible.jpg";
// import bgd from "../guestcomponents/Images/harvest22.png";
import bgm from "../guestcomponents/Images/oldlady_biblem.jpg"; 
// import har from "../guestcomponents/Images/harvest.jpg";    
import { breakpoints as bp } from "../GlobalStyle";

const imageUrl = window.innerWidth >= 500 ? bgd : bgm;
const Styles = styled.div`
  .col-sm-2 {
    color: black;
  }
  .bg_image {
    padding-left: 10px;
  }
  .link_login {
    color: blue;
    margin-top: -45px;
    padding-right: 30px;
    padding-bottom: 5px;
  }
  .link_forgot {
    color: dodgerblue;
    padding-bottom: 40px;
  }
  .form {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .label {
    padding-right: 37px;
    padding-bottom: 3px;
    margin-bottom: 0.3rem;
    color: #fff;
  }
 
  .button_login {
    padding-left: 200px;
    borderradius: 50;
  }
  .rcorners2 {
    background-color: black;
    opacity: 0.75;
    border-radius: 30px;
    border: 2px solid #000000;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 350px;
    height: 280px;
    margin-left: 2rem;
  }
  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  @media (max-width: ${bp.mobile}) {
    .rcorners2 {
      border-radius: 45px;
      border: 2px solid #000000;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 300px;
      height: 280px;
      margin-left: 0rem;
      margin-top: -1.5rem;
    }
    .link_login {
      color: blue;
      margin-top: -45px;
      padding-right: 30px;
      padding-bottom: 5px;
    }
    .link_forgot {
      color: dodgerblue;
      padding-bottom: 30px;
    }
    .button_login {
      padding-left: 50px;
      borderradius: 50;
    }
  }
`;

const Button = styled.button`
background-color: #F0F0F0;
color: black;
font-size: 15px;
width: 70px;
height: 30px;
padding: -10px 3px 5px 0px;
// box-shadow: 5px 7px #F0F0F0;
box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
border-color: #F0F0F0;
border-radius: 10px;
margin: 0px 25px 16px 35px;
cursor: pointer;
// &:disabled {
//   color: grey;
//   opacity: 0.7;
//   cursor: default;
// @media (max-width: ${bp.desktop}) {
//  margin: 200px 25px 16px 250px;
//  padding: -10px 3px 5px 0px;
//   width: 70px;
// height: 30px; }

@media (max-width: ${bp.mobile}) {
  margin: 0px 25px 16px 135px;
  padding: 2px 3px 5px 2px;
   width: 70px;
 height: 30px; }
}
`;

function Login() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");

  const { handleSubmit } = useForm();
  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      history.push("./courses");
    }
  });
  async function login() {
    let item = { email, password };

    let result = await fetch(
      `${process.env.React_App_ccbt}/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      }
    );
    result = await result.json();
 
    var a = result.token;

    var res = JSON.stringify(result);
    if (a !== undefined) {
      localStorage.setItem("user-info", JSON.stringify(result));
      history.push("/courses");
    } else {
      alert("Please check your credentials!")
      history.push("/login");
    }
  }
  return (
    <div>
      <Header />

      <Styles>
        <div
          class="bg_image"
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "cover",
            height: "95vh",
            color: "#000000",
            top: '-10px'
          }}
        >
          <h1>
            <br />
          </h1>
          <div className="rcorners2">
            
            <div class="form">
              <label className="label">Email</label>
              <input
                class
                type="email"
                id="email"
                pattern=".+@globex\.com"
                size="30"
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                required
              />
              {Object.keys(emailErr).map((key) => {
                return <div style={{ color: "red" }}>{emailErr[key]}</div>;
              })}
              <br />
              <label className="label">Password</label>
              <input
                class
                type="password"
                // label="Password"
                // placeholder="password"
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                required
              />
              {Object.keys(passwordErr).map((key) => {
                return <div style={{ color: "red" }}>{passwordErr[key]}</div>;
              })}
              <br />
              <div className="button_login">
                <Button
                  disabled={!email || !password}
                  onClick={login}
                >
                  <h12>Login</h12>
                </Button>
              </div>
              <div className="link_login">
                <Link className="link_forgot" to={"/forgot"}>
                  Forgot Password?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Styles>
    </div>
  );
}
export default Login;
